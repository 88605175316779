import React from 'react';
import styles from './Navbar.module.css';
import logo from '../../assets/logoAbaco.png';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => {
  return (
    <div className={styles['navbar_container']}>
      <div className={styles.logo}>
       
        <ScrollLink
              activeClass='active'
              className='anchor_link'
              to='hero'
              spy={true}
              smooth={true}
              duration={500}
              offset={-90}
            >
               <img src={logo} alt='Logo Abaco' />
            </ScrollLink>
      </div>
      <nav>
        <ul>
          <li>
            <ScrollLink
              activeClass='active'
              className='anchor_link'
              to='quienes-somos'
              spy={true}
              smooth={true}
              duration={500}
              offset={-90}
            >
              ¿Quiénes somos?
            </ScrollLink>
          </li>
          <li><ScrollLink
              activeClass='active'
              className='anchor_link'
              to='servicios'
              spy={true}
              smooth={true}
              duration={500}
              offset={-90}
            >
              Servicios
            </ScrollLink></li>
          <li><ScrollLink
              activeClass='active'
              className='anchor_link'
              to='contacto'
              spy={true}
              smooth={true}
              duration={500}
              offset={-90}
            >
              Contacto
            </ScrollLink></li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
