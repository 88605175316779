import React from 'react';
import styles from './Hero.module.css';
import logo from '../../assets/logoAbaco.png';
import guarda from '../../assets/guarda.svg';

const Hero = () => {
  return (
    <section className={styles['hero_container']}>
      <div className={styles['logo_wrapper']}>
        <img src={logo} alt='Abaco Cooperativa' />
      </div>
      <img className={styles.guarda} src={guarda} alt='Abaco Cooperativa' />
    </section>
  );
};

export default Hero;
