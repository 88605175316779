import React from 'react';
import styles from './About.module.css';
import qs from '../../assets/qs.svg';
import { Link as ScrollLink } from 'react-scroll';

const About = () => {
  return (
    <section className={styles['about_container']}>
      <div class='inner'>
        <div className='grid'>
          <div className='col_6'>
            <h1>¿Quiénes somos?</h1>
            <p>
              Abaco es una cooperativa de trabajo asociado, integradas por
              personas especializadas en la gestión de pagos y cobranzas por
              cuenta y orden de terceros como así también en la procesamiento y
              digitalización de información.
            </p>
            <ScrollLink
              activeClass='active'
              className='btn-primary'
              to='contacto'
              spy={true}
              smooth={true}
              duration={500}
              offset={-90}
            >
              Contactanos
            </ScrollLink>
          </div>
          <div className={`col_6 ${styles.image_wrapper}`}>
            <img src={qs} alt='Quienes somos' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
