import React from 'react';
import styles from './Services.module.css';
import service1 from '../../assets/pagos-1.svg';
import service2 from '../../assets/digitalizacion-2.svg';
import service3 from '../../assets/sistemas-3.svg';
import service4 from '../../assets/facturacion-4.svg';

const Services = () => {
  return (
    <section className={styles['services_container']}>
      <div className='inner'>
        <h1>Servicios</h1>
        <div className={`grid ${styles.servicios_grid}`}>
          <div className={`col_3 ${styles.servicio}`}>
            <div
              className={styles.image_wrapper}
              style={{ borderColor: '#F9AE4B' }}
            >
              <img src={service1} alt='Servicio 1' />
            </div>
            <p>
              Gestión de los servicios de pagos y cobranzas a los distintos
              proveedores/clientes de distintos bienes y servicios.
            </p>
          </div>
          <div className={`col_3 ${styles.servicio}`}>
            <div
              className={styles.image_wrapper}
              style={{ borderColor: '#F9AE4B' }}
            >
              <img src={service2} alt='Servicio 2' />
            </div>
            <p>
              Proceso y digitalización de información referente al circuito
              administrativo de las Empresas
            </p>
          </div>
          <div className={`col_3 ${styles.servicio}`}>
            <div
              className={styles.image_wrapper}
              style={{ borderColor: '#F9AE4B' }}
            >
              <img src={service3} alt='Servicio 3' />
            </div>
            <p>
              Análisis e informes sobre los sistemas de información
              administrativa.
            </p>
          </div>
          <div className={`col_3 ${styles.servicio}`}>
            <div
              className={styles.image_wrapper}
              style={{ borderColor: '#F9AE4B' }}
            >
              <img src={service4} alt='Servicio 4' />
            </div>
            <p>
              Análisis y liquidación de trabajos realizados por otras entidades
              de la economía social, para la consecuente facturación de
              servicios prestados y distribución de excedentes entre los
              asociados.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
