import React from 'react';
import Hero from '../components/Hero/Hero';
import About from '../components/About/About';
import Mission from '../components/Mission/Mission';
import Services from '../components/Services/Services';
import Contact from '../components/Contact/Contact';
import { Element } from 'react-scroll';

const Home = () => {
  return (
    <>
      <Element name='hero' className='element'>
        <Hero />
      </Element>
      <Element name='quienes-somos' className='element'>
        <About />
      </Element>
      <Element name='mision' className='element'>
        <Mission />
      </Element>
      <Element name='servicios' className='element'>
        <Services />
      </Element>
      <Element name='contacto' className='element'>
        <Contact />
      </Element>
    </>
  );
};

export default Home;
