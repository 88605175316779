import React from 'react';
import styles from './Mission.module.css';

const Mission = () => {
  return (
    <section className={styles['mission_container']}>
      <div class='inner'>
        <h1>Misión</h1>
        <p>
          Nuestro objetivo principal respecto de nuestros Clientes es proveer
          soluciones inteligentes a los efectos de optimizar beneficios y
          reducir riesgos.
        </p>
        <p>
          El objetivo con nuestros asociados es fomentar su desarrollo personal
          y profesional que permita satisfacer sus aspiraciones sociales y
          económicas dentro de una estructura democrática.
        </p>
      </div>
    </section>
  );
};

export default Mission;
