import React from 'react';
import styles from './Footer.module.css';
import logo from '../../assets/logoAbacoFooter.png';


const Footer = () => {
  return <div className={styles['footer_container']}><img src={logo} alt='Abaco Cooperativa' /></div>;
};

export default Footer;
