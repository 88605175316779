import React from 'react';
import styles from './Contact.module.css';
import ContactForm from '../ContactForm/ContactForm';

const Contact = () => {
  return <section className={styles['contact_container']}>
    <div className="inner">
        <h1>Contacto</h1>
        <ContactForm />
    </div>
  </section>;
};

export default Contact;
