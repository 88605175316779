import React, {
  useState,
  //  useCallback
} from 'react';
import styles from './ContactForm.module.css';
import { Formik, Form, Field } from 'formik';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [mailResponse, setMailResponse] = useState(null);

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'Requiredo';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalido';
    }
    return error;
  };

  const validateUsername = (value) => {
    let error;
    if (value === '') {
      error = 'Requerido';
    }
    return error;
  };
  const validateTelefono = (value) => {
    let error;
    if (value === '') {
      error = 'Requerido';
    }
    return error;
  };

  const validateMessage = (value) => {
    let error;
    if (value === '') {
      error = 'Requerido';
    }
    return error;
  };

  const addContact = async (
    email,
    userName,
    userApellido,
    telefono,
    mensaje,
    resetForm
  ) => {
    console.log(mensaje)
    const templateParams = {
      name: userName,
      email,
      userApellido,
      telefono,
      mensaje,
    };
    emailjs
      .send(
        'service_l03hwvm',
        'template_kpsk5az',
        templateParams,
        'tvUxaQhZWBJg2TPeo'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setMailResponse(response.text);
          console.log('respoonse', response);
          resetForm();
          setTimeout(() => {
            setMailResponse(null);
          }, 3000);
        },
        (err) => {
          console.log('FAILED...', err);
          setMailResponse('Ha ocurrido un error, por favor intente más tarde');
          console.log('respoonse', err);
          resetForm();
          setTimeout(() => {
            setMailResponse(null);
          }, 3000);
        }
      );
  };

  return (
    <div className={styles.form_container}>
      <Formik
        initialValues={{
          email: '',
          userName: '',
          userApellido: '',
          telefono: '',
          mensaje: '',
        }}
        onSubmit={(values, { resetForm }) => {
          addContact(
            values.email,
            values.userName,
            values.userApellido,
            values.telefono,
            values.mensaje,
            resetForm
          );
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className={`grid ${styles.form_grid}`}>
              <div className={`col_6 ${styles.form_col}`}>
                <Field
                  name='userName'
                  placeholder='Nombre'
                  validate={validateUsername}
                />
                {errors.userName && touched.userName && (
                  <div className={styles.validator}>{errors.userName}</div>
                )}
              </div>
              <div className={`col_6 ${styles.form_col}`}>
                <Field name='userApellido' placeholder='Apellido' />
              </div>
              <div className={`col_6 ${styles.form_col}`}>
                <Field
                  name='email'
                  type='email'
                  placeholder='Email'
                  validate={validateEmail}
                />
                {errors.email && touched.email && (
                  <div className={styles.validator}>{errors.email}</div>
                )}
              </div>
              <div className={`col_6 ${styles.form_col}`}>
                <Field
                  name='telefono'
                  placeholder='Teléfono'
                  validate={validateTelefono}
                />
                {errors.telefono && touched.telefono && (
                  <div className={styles.validator}>{errors.telefono}</div>
                )}
              </div>
              <div className={`col_12 ${styles.form_col}`}>
                <Field
                  name='mensaje'
                  as='textarea'
                  placeholder='Mensaje'
                  validate={validateMessage}
                />
                {errors.telefono && touched.telefono && (
                  <div className={styles.validator}>{errors.telefono}</div>
                )}
              </div>
            </div>

            {mailResponse && (
              <div className={styles.validator}>{mailResponse}</div>
            )}
            <button
              className={`btn-primary`}
              type='submit'
              disabled={isSubmitting}
            >
              Enviar
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
